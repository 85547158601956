import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Scaling LLM-based products" />
      <h1>Building great LLM-powered products</h1>

      <p>Most AI startups are competing to beat artificial benchmarks, find the next research breakthrough.</p>

      <p>We believe LLMs are already powerful enough to build great products <b>today</b>.</p>

      <h1>StemAI program</h1>
      <ul>
        <li>$250,000 SAFE investment</li>
        <li>$350,000 in cloud credits from StemAI partners</li>
        <li>Frequent checkpoints with world-class AI advisors</li>
        <li>Access to external funding</li>
        <li>Summit with advisors and fellow founders, hosted in Paris</li>
      </ul>

      <h1>Dedicated advisors</h1>
      <p>
        Get ongoing help from world-class advisors, with experience building successful LLM and ML-powered products with millions of daily users.
        </p>
      <p>
        Users don't care how the sausage is made, so getting the right guidance on <b>product</b> and <b>UX</b> can make or break your product.
      </p>

  <h1>Strong community</h1>
  <p>Our goal is to build a strong community of people laser-focused on bringing AI-powered products to a global audience.</p>
  <p>No buzzwords, no philosophical BS, you'll meet the people building truly useful products today.</p>
  <p></p>
      <Link to="/apply" className="call-to-action">Apply now</Link>
    </Layout>
  )
}

export default IndexPage
